/* Main styles */
@keyframes pulse {
    0% {
        background-color: rgb(1, 97, 129);
    }
    50% {
        background-color: rgb(1, 67, 89);
    }
    100% {
        background-color: rgb(1, 97, 129);
    }
}

.impacts-sections {
    background-color: rgb(1, 97, 129);
    padding: 5% 5% 5% 100px; /* Large left padding for larger screens */
    color: black;
    width: 100%;
    box-sizing: border-box; /* Ensure padding doesn't cause overflow */
}

.question-working-impacts,
.question-working-sub,
.content-answer {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.question-working-impacts {
    font-weight: 600;
    font-size: 2rem;
    color: white;
}

.question-working-sub {
    font-weight: 600;
    font-size: 1.5rem;
}

.content-answer {
    margin-top: 1.5rem;
    font-size: 1rem;
    margin-right: 5%;
    font-weight: 400;
    max-width: 85%;
}

.content-answer-tnc {
    margin-top: 1rem;
    margin-bottom: 2rem;
    font-size: 1rem;
    margin-right: 1rem;
    font-weight: 400;
    max-width: 85%;
}

.step-grid-impact {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 50px 0 0;
    gap: 2rem;
    padding: 1rem 0;
}

.step-grid-impact-tnc {
    display: grid;
    grid-template-columns: 1fr; /* Only one grid column */
    margin: 50px 0 0;
    gap: 2rem;
    padding: 1rem 0;
}

.step-impact {
    background-color: white;
    border-radius: 20px;
    padding: 2rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.gridlogo {
    height: 60px;
    margin-left: 10px;
    margin-bottom: 10px;
}

/* Responsive styles */

@media (max-width: 1024px) {
    .content-answer {
        margin-right: 50px;
    }
}

@media (max-width: 768px) {
    .impacts-sections {
        padding: 5%; /* Adjusted padding for smaller screens */
    }

    .step-grid-impact {
        grid-template-columns: 1fr;
    }

    .content-answer {
        margin-right: 20px;
    }
}

@media (max-width: 480px) {
    .question-working-impacts {
        font-size: 24px;
    }

    .question-working-sub {
        font-size: 20px;
    }

    .content-answer {
        font-size: 16px;
    }
}
