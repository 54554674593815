.signup-comp {
    display: flex;
    flex-direction: column; /* Start with column layout for mobile */
    column-gap: 5px;
    row-gap: 5px;
    background-color: #36a8a9;
    padding: 30px 20px; /* Reduce padding for smaller screens */
    color: white;
    width: 100%;
    box-sizing: border-box;
}

.question-working-signup {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 300;
    margin-left: 20px;
    font-size: 24px; /* Smaller font size for mobile */
    margin-bottom: 20px; /* Vertical margin for mobile layout */
    text-align: center; /* Center the text for mobile view */
}

.join-button {
    text-align: center; /* Center the button for mobile view */
}

.signup-button {
    padding: 10px 20px; /* Smaller padding for mobile */
    background-color: white;
    font-weight: bold;
    border-radius: 25px;
}

.signup-button:active {
    background-color: black;
    color: white;
    opacity: 0.5;
    cursor: pointer;
}

.signup-button:hover {
    background-color: black;
    color: white;
    cursor: pointer;
}

/* Media query for larger screens, e.g., tablets and desktops */
@media (min-width: 768px) { /* This is a common breakpoint for tablets */
    .signup-comp {
        flex-direction: row; /* Change to row layout for larger screens */
        padding: 80px;
    }

    .question-working-signup {
        flex: 2;
        justify-content: center;
        font-size: 40px;
        margin-right: 100px;
        margin-bottom: 0; /* Remove the vertical margin */
        text-align: left; /* Align text to the left for larger screens */
    }

    .join-button {
        margin-top: 35px;
        margin-right: 100px;
        text-align: left; /* Align button to the left for larger screens */
    }

    .signup-button {
        padding: 15px;
    }
}

