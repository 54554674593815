@keyframes pulse {
  0% {
      background-color: white;
  }
  50% {
      background-color: rgb(211, 211, 238);
  }
  100% {
      background-color: white;
  }
}

body {
  background-color: white;
}

/* General Navbar Styles */
.navbar,
.navbar-styles {
  height: 100px;
  background-color: white !important;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  animation: pulse 5s infinite; 
}

.navbar-brand,
.navbar-toggler,
.navbar-nav,
.custom-nav-link {
  height: 100px;
  display: flex;
  align-items: center; /* Center items vertically */
}

/* Navbar Brand & Logo Styles */
.madas-health-logo {
  height: 60px;
  margin-right: 10px;
}

/* Navbar Brand & Logo Styles */
.madas-health-logo-flag {
  height: 40px;
  margin-right: 10px;
}

.logo-label {
  color: rgb(54, 10, 232);
  font-size: 30px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin-left: 10px;
}

.logo-label-country {
  color: gray;
  font-size: 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin-left: 10px;
}

/* Navbar Link Styles */
.custom-nav-link {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold;
  font-size: 15px;
  margin-left: 15px;
  margin-right: 15px;
  color: rgb(54, 10, 232) !important;
  width: 100%;
}

.navbar-collapse .nav-link {
  background-color: white !important;  
}

/* Navbar Toggler (Hamburger Button) Styles */
.navbar-toggler {
  border: none; /* Removes the border */
  background-color: transparent !important; /* Ensures the button background remains consistent */
}

.navbar-toggler:focus,
.navbar-toggler:hover {
  border: none;
  outline: none;
  box-shadow: none; /* Removes any potential shadows on focus/hover */
}

/* Adjustments for Smaller Screens */
@media screen and (max-width: 576px) {
  .madas-health-logo-flag,
  .madas-health-logo {
      height: 40px;
  }

  .logo-label {
      font-size: 20px;
  }

  .custom-nav-link {
      font-size: 14px;
  }

  .navbar,
  .navbar-styles,
  .navbar-brand,
  .navbar-toggler,
  .navbar-nav {
      height: auto;
  }

  .navbar-nav {
    justify-content: flex-start; /* This aligns the navbar items to the left */
}

  .navbar-nav .nav-item {
      align-items: flex-start; /* This aligns each individual navbar item to the left */
  }

  .navbar-collapse {
    background-color: white !important; 
    text-align: left;   /* align text to the left */
  }

  .navbar-nav .nav-item {
    display: block;     /* stack items vertically */
    width: 100%;        /* occupy the full width */
    text-align: left;   /* align text to the left */
  }
  
  .navbar-nav {
    flex-direction: column; /* stack items vertically */
    width: 100%;            /* occupy the full width */
  }
}

.active-nav-link {
  /* Add your styling for the active link here */
  color: #fff; /* Example: white color */
  background-color: #007bff; /* Example: background color */
  font-weight: lighter; /* Example: make it bold */
}
