/* Main styles for information-sections */
@keyframes infoPulse {
    0%, 100% {
        background-color: white;
    }
    50% {
        background-color: white;
    }
}

.information-sections {
    background-color: white;
    padding: 5% 5% 5% 100px; /* Retained 100px left padding for larger screens */
    color: black;
    width: 100%;
    animation: infoPulse 5s infinite;
}

.information-sections-edu {
    background-color: rgb(248, 236, 236);
    padding: 5% 5% 5% 100px; /* Retained 100px left padding for larger screens */
    color: black;
    width: 100%;
}

.question-working,
.question-working-sub {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
}

.question-working {
    font-size: 2rem;
}

.question-working-sub {
    font-size: 1.5rem;
}

.content-answer {
    margin-top: 1.5rem;
    font-size: 1rem;
    margin-right: 5%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
    max-width: 85%;
}

.step-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    padding: 1rem 0;
    color: black;
}

.step-grid-priv-pol {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    padding: 1rem 0;
}

.step-edu,
.step {
    color: black;
    padding: 2rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.step {
    background-size: cover;  /* cover the entire div */
    background-repeat: no-repeat;  /* do not repeat the image */
    background-position: center center;  /* center the image */
}

.step-home {
    background-size: cover;  /* cover the entire div */
    background-repeat: no-repeat;  /* do not repeat the image */
    background-position: center center;  /* center the image */
    color: black;
    padding: 5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.step-home:hover,
.step:hover {
    transform: scale(1.02);
}

.step-priv-pol {
    background-color: white;
    border-radius: 20px;
    padding: 2rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.step-edu {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.right-section {
    flex: 1;
    padding: 10px;
}

.left-section {
    flex: 1;
    width: 50%;
    height: 100%;
    overflow: hidden;
    padding-right: 20px;
}

.left-section img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.gridlogo {
    max-width: 100%; 
    height: auto; 
    margin: 0.5rem 1rem 1rem 0;
}

.step-edu-specialist-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
}

.step-edu-specialist-main-colour {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    background-color: #36a8a9
}
  
.slide-content-spec {
    display: flex;
    flex-direction: row;
    width: 100%;
    box-sizing: border-box;
}
  
.logo-block-spec, .text-block-spec {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    box-sizing: border-box;
}
  
.gridlogo-spec {
    max-width: 100%;
    height: auto;
}

.gridlogo-spec-slider {
    max-width: 80%;
    height: auto;
}

.gridlogo-spec-logo {
    max-width: 50%;
    height: auto;
}

/* Responsive for smaller screens */

@media (max-width: 768px) {
    .information-sections,
    .information-sections-edu {
        padding: 5%; /* Adjusted padding for smaller screens */
    }

    .left-section {
        width: 100%; /* Left section takes full width on mobile */
        padding-right: 10px; /* Reduce padding for mobile */
    }

    .step-grid {
        grid-template-columns: 1fr;
    }

    .question-working {
        font-size: 2rem;
    }

    .question-working-sub {
        font-size: 1.8rem;
    }

    .content-answer {
        font-size: 1.1rem;
        margin-right: 2%; /* Adjusted margin for smaller screens */
        max-width: 100%; /* Adjusted max-width for smaller screens */
    }

    .step-edu,
    .step,
    .step-priv-pol {
        padding: 1rem; /* Adjusted padding for smaller screens */
    }

    .right-section {
        padding: 5px; /* Adjusted padding for smaller screens */
    }

    .step-edu-specialist-main-colour,
    .step-edu-specialist-main,
    .slide-content-spec {
        flex-direction: column; /* Changed layout to column for smaller screens */
    }

    .logo-block-spec, 
    .text-block-spec {
        padding: 5px; /* Adjusted padding for smaller screens */
    }

    .gridlogo,
    .gridlogo-spec-logo,
    .gridlogo-spec {
        max-width: 80%; /* Adjusted width for smaller screens */
        margin: 0 auto; /* Centered logo for smaller screens */
    }
}
