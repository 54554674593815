.testimonials {
    background-color: white;
    padding: 100px;
    color: black;
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;    
}

.testimonials-slider {
    background-color: #36a8a9; /* Specifies the background color */
    padding: 100px; /* Adds padding around the content for spacing */
    color: white; /* Sets the text color to white for contrast */
    width: 100%; /* Ensures the slider takes the full width of its parent container */
    height: auto; /* Automatically adjusts the height based on the content */
    position: relative; /* Positions the slider relative to its normal position */
    overflow: hidden; /* Hides any content that overflows the slider's bounds */
    box-sizing: border-box; /* Ensures padding does not affect the overall dimensions */
}


.slider {
    overflow: hidden;
}

.slider__inner {
    display: flex;
    width: 500%;
    transition: all 0.5s ease;
}

.slider__contents {
    flex: 1;
    display: flex;
    flex-direction: row;
    background: rgb(220, 218, 218);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    box-sizing: border-box;
    width: calc(20%);
    border-radius: 15px;
    height: auto;
}

.slider__txt {
    margin-right: 100px;
}

.slider__radio {
    display: none;
}

#slide1:checked ~ .slider .slider__inner {
    transform: translateX(0%);
}

#slide2:checked ~ .slider .slider__inner {
    transform: translateX(-20%);
}

#slide3:checked ~ .slider .slider__inner {
    transform: translateX(-40%);
}

#slide4:checked ~ .slider .slider__inner {
    transform: translateX(-60%);
}

#slide5:checked ~ .slider .slider__inner {
    transform: translateX(-80%);
}

.slider__controls {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 15px;
}

.slider__label {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: rgb(220, 218, 218);
    cursor: pointer;
    transition: transform 0.3s ease;
    border: 2px solid transparent;
}

#slide1:checked ~ .slider__controls .slider__label:nth-child(1),
#slide2:checked ~ .slider__controls .slider__label:nth-child(2),
#slide3:checked ~ .slider__controls .slider__label:nth-child(3),
#slide4:checked ~ .slider__controls .slider__label:nth-child(4),
#slide5:checked ~ .slider__controls .slider__label:nth-child(5) {
    transform: scale(1.3);
    border: 2px solid #333;
}

.left-section-slider-intern,
.left-section-slider {
    flex: 1;
    overflow: hidden;
    padding: 10px;
    align-items: center;
    justify-content: center;
}

.left-section-slider img {
    width: 100%;
    height: auto;
    padding-top: 25%;
}

.left-section-slider-intern img {
    width: 100%;
    height: auto;
}

.right-section-slider {
    flex: 1;
    padding: 50px;
}

.right-section-slider-intern {
    flex: 2;
    padding: 50px;
}

.slider__image.fa {
    font-size: 20px;
}

/* Responsiveness */
@media only screen and (max-width: 768px) {
    .testimonials {
        padding: 50px;
    }

    .slider__inner {
        flex-direction: column;
        width: 100%;
    }

    .slider__contents {
        width: 100%;
        flex-direction: column;
        margin-bottom: 30px;
    }

    .left-section-slider-intern,
    .right-section-slider-intern,
    .left-section-slider, .right-section-slider {
        flex: none;
        width: 100%;
        padding: 20px 0;
    }

    .slider__radio, .slider__controls {
        display: none;
    }
}

@media only screen and (max-width: 480px) {
    .testimonials {
        padding: 20px;
    }

    .slider__contents {
        padding: 10px;
    }

    .slider__txt {
        margin-right: 0;
    }

    .right-section-slider-intern,
    .right-section-slider {
        padding: 20px;
    }
}
