.jumbotron,
.jumbotron-med,
.jumbotron-mission {
    display: flex;
    flex-wrap: wrap;
    column-gap: 1rem;
    row-gap: 1rem;      
    color: white;
    width: 100%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    box-sizing: border-box;
}

.jumbotron {
    position: relative; /* Make this a positioning context for the video */
    flex-direction: row;
    padding-top: calc(7rem + 100px);
    background-color: transparent;
    width: 100%;
    height: 100vh; /* Make it take the entire viewport height */
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    box-sizing: border-box;
}

.jumbotron-mv-main {
    display: flex;
    flex-wrap: wrap;
    column-gap: 1rem;
    row-gap: 1rem;      
    color: black;
    width: 100%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    box-sizing: border-box;
    flex-direction: row;
    padding: 50px; 
    padding-top: calc(0.5rem + 100px);
    padding-bottom: 100px;
    background-color: rgb(209, 207, 207);
    align-items: center;
    background-image: url('../icons/font_page_2.jpeg'); /* Path to your image */
    background-size: cover; /* Cover the entire jumbotron */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Prevent repeating the image */
}

.jumbotron-mv-main-collab {
    display: flex;
    flex-wrap: wrap;
    column-gap: 1rem;
    row-gap: 1rem;      
    color: black;
    width: 100%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    box-sizing: border-box;
    flex-direction: row;
    padding: 50px; 
    padding-top: calc(0.5rem + 100px);
    padding-bottom: 100px;
    background-color: rgb(209, 207, 207);
    align-items: center;
    background-image: url('../icons/font-page-3.png'); /* Path to your image */
    background-size: cover; /* Cover the entire jumbotron */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Prevent repeating the image */
}

.jumbotron-home {
    display: flex;
    flex-direction: column; /* Ensures vertical alignment of children */
    justify-content: flex-start; /* Aligns children to the top of the container */
    align-items: center; /* Centers children horizontally */
    flex-wrap: wrap; /* Allows children to wrap to the next line if needed */
    column-gap: 1rem; /* Space between columns */
    row-gap: 1rem; /* Space between rows */
    color: white;
    width: 100%;
    min-height: 100vh; /* Minimum height to fill the viewport, but can grow with content */
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    box-sizing: border-box;
    padding: 50px; 
    padding-top: calc(0.5rem + 100px); /* Adjusted top padding */
    padding-bottom: 100px; /* Consistent bottom padding */
    background-color: #36a8a9;
    text-align: center; /* Central alignment of text */
    /* Optional background image settings */
    /*background-image: url('../icons/font-page-3.png');*/
    /*background-size: cover;*/
    /*background-position: center;*/
    /*background-repeat: no-repeat;*/
}

.jumbotron-home-digital-chakra {
    display: flex;
    flex-direction: column; /* Ensures vertical alignment of children */
    justify-content: flex-start; /* Aligns children to the top of the container */
    align-items: center; /* Centers children horizontally */
    flex-wrap: wrap; /* Allows children to wrap to the next line if needed */
    column-gap: 1rem; /* Space between columns */
    row-gap: 1rem; /* Space between rows */
    color: black;
    width: 100%;
    min-height: 100vh; /* Minimum height to fill the viewport, but can grow with content */
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    box-sizing: border-box;
    padding: 50px; 
    padding-top: calc(0.5rem + 100px); /* Adjusted top padding */
    padding-bottom: 100px; /* Consistent bottom padding */
    background-color: white;
    text-align: center; /* Central alignment of text */
    /* Optional background image settings */
    /*background-image: url('../icons/font-page-3.png');*/
    /*background-size: cover;*/
    /*background-position: center;*/
    /*background-repeat: no-repeat;*/
}

.jumbotron-home-goals {
    display: flex;
    flex-direction: column; /* Ensures vertical alignment of children */
    justify-content: flex-start; /* Aligns children to the top of the container */
    align-items: center; /* Centers children horizontally */
    flex-wrap: wrap; /* Allows children to wrap to the next line if needed */
    column-gap: 1rem; /* Space between columns */
    row-gap: 1rem; /* Space between rows */
    color: white;
    width: 100%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    box-sizing: border-box;
    padding: 20px; 
    padding-top: calc(0.5rem + 100px); /* Adjusted top padding */
    padding-bottom: 20px; /* Consistent bottom padding */
    background-color: #36a8a9;
    text-align: center; /* Central alignment of text */
    /* Optional background image settings */
    /*background-image: url('../icons/font-page-3.png');*/
    /*background-size: cover;*/
    /*background-position: center;*/
    /*background-repeat: no-repeat;*/
}


.jumbotron-home-cyber {
    width: 100%; /* Make the div take the full width of its parent container */
    height: auto; /* Keep the aspect ratio of the image */
    padding: 16px; /* Add uniform padding on all sides */
    box-sizing: border-box; /* Include padding in the element's total width and height */
    position: relative; /* Position relative for absolute centering */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .jumbotron-image-cyber {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain; /* Ensure the image fits within the div with its aspect ratio preserved */
  }

.jumbotron-med {
    flex-direction: row;
    padding: 100px; 
    background-color: black(1, 97, 129);
}

.jumbotron-aboutUs {
    display: flex;
    flex-wrap: wrap;
    column-gap: 1rem;
    row-gap: 1rem;      
    color: black;
    width: 100%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    box-sizing: border-box;
    flex-direction: row;
    padding: 50px; 
    padding-top: 0px;
    padding-bottom: 100px;
    background-color: white;
}

.jumbotron-aboutUs-footer {
    display: flex;
    flex-wrap: wrap;
    column-gap: 1rem;
    row-gap: 1rem;      
    color: white;
    width: 100%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    box-sizing: border-box;
    flex-direction: row;
    padding: 50px; 
    padding-top: 0px;
    padding-bottom: 100px;
    background-color: #36a8a9;
}

.jumbotron-mv-medispeak {
    display: flex;
    flex-wrap: wrap;
    column-gap: 1rem;
    row-gap: 1rem;      
    color: black;
    width: 100%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    box-sizing: border-box;
    flex-direction: row;
    padding: 50px; 
    padding-top: 0px;
    padding-bottom: 100px;
    background-color: light#36a8a9;
    align-items: center;
    background-image: url('../icons/healthcare-cover-screen.png'); /* Path to your image */
    background-size: cover; /* Cover the entire jumbotron */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Prevent repeating the image */
}

.jumbotron-mv {
    display: flex;
    flex-wrap: wrap;
    column-gap: 1rem;
    row-gap: 1rem;      
    color: black;
    width: 100%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    box-sizing: border-box;
    flex-direction: row;
    padding: 50px; 
    padding-top: 0px;
    padding-bottom: 100px;
    background-color: light;
    align-items: center;
}

.jumbotron-mv-colour {
    display: flex;
    flex-wrap: wrap;
    column-gap: 1rem;
    row-gap: 1rem;      
    color: white;
    width: 100%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    box-sizing: border-box;
    flex-direction: row;
    padding: 50px; 
    padding-top: 0px;
    padding-bottom: 100px;
    background-color: #36a8a9;
    align-items: center;
}

.jumbotron-mv-team {
    display: flex;
    flex-wrap: wrap;
    column-gap: 1rem;
    row-gap: 1rem;      
    color: white;
    width: 100%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    box-sizing: border-box;
    flex-direction: row;
    padding: 50px; 
    padding-top: 0px;
    padding-bottom: 100px;
    background-color: light;
    align-items: center;
    background-color: #36a8a9;
}

.jumbotron-ourTeam {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    column-gap: 1rem;
    row-gap: 1rem;      
    color: black;
    width: 100%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    box-sizing: border-box;
    flex-direction: row;
    padding: 50px; 
    padding-top: 0px;
    padding-bottom: 100px;
    background-color: white;
}

.jumbotron-video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -1;
    transform: translate(-50%, -50%);
    object-fit: cover; /* Ensure the video covers the container without distortion */
}

.heading,
.heading-prod,
.heading-med {
    flex: 2;
    justify-content: center;
    padding: 0.5rem;
    color: inherit;
    font-size: 2rem;
    font-weight: 400;
    box-sizing: border-box;
}

.heading {
    margin-left: 4rem;
    margin-top: 4rem;
}

.heading-home {
    flex: 2;
    justify-content: center;
    padding: 0.5rem;
    color: inherit;
    font-size: 2rem;
    font-weight: 400;
    box-sizing: border-box;
    margin-left: 4rem;
    margin-top: 4rem;
}

.heading-med {
    margin-top: 0px;
}

.heading-prod {
    margin-left: 4rem;
    margin-top: 4rem;
    color: black;
}

.content-prod,
.content {
    margin-top: 1.25rem;
    font-size: 1rem;
    box-sizing: border-box;
}

.content-home {
    margin-top: 1.25rem;
    font-size: 1.5rem;
    box-sizing: border-box;
}

.content-extra {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin-top: 1.25rem;
    font-size: 1rem;
    box-sizing: border-box;
    padding-left: 50px;
    font-weight: 400;
}

.youtube-player {
    margin-top: 5rem;
    margin-right: 1.25rem;
    box-sizing: border-box;
    padding-top: 50px;
}

.youtube-player-mv {
    margin-top: 5rem;
    margin-right: 1rem;
    padding-left: 3rem;
    box-sizing: border-box;
}

.youtube-player-mission {
    margin-top: 1.5rem;
    margin-right: 1.25rem;
    margin-left: 6rem;
    box-sizing: border-box;
}

.heading-mission {
    flex: 2;
    justify-content: center;
    padding: 0.5rem;
    color: inherit;
    margin-left: 0.625rem;
    font-size: 2rem;
    font-weight: 400;
    box-sizing: border-box;
}

.jumbotron-mission {
    flex-direction: row;
    background-color: rgb(26, 176, 227);
    padding-left: 0;
    padding-right: 0;
    padding-top: 6.25rem;
}

/* Tablets in landscape and low-resolution desktops (1024px) */
@media (max-width: 1024px) {
    /* Adjust flex containers to column layout */
    .jumbotron, .jumbotron-mv-colour ,.jumbotron-aboutUs, .jumbotron-home-goals, .jumbotron-mv, .jumbotron-ourTeam, .jumbotron-med, .jumbotron-mv-main, .jumbotron-mv-main-collab, .jumbotron-home, .jumbotron-home-cyber, .jumbotron-mv-medispeak, .jumbotron-mv-team, .jumbotron-ourTeam, .jumbotron-video, .jumbotron-mission {
        flex-direction: column;
        align-items: center; /* Center content for a better visual appeal */
        padding: 20px;
    }

    /* Adjust heading, content, and other textual elements for smaller screen readability */
    .heading, .content, .youtube-player, .youtube-player-mission, .heading-mission, .youtube-player-mv, .heading-home, .content-home, .content-prod, .content-extra {
        font-size: smaller; /* Adjust font size for readability */
        text-align: center;
        margin: 10px;
    }
}


/* Large smartphones (600px) */
@media (max-width: 600px) {
    /* Adjust flex containers to column layout with centered alignment */
    .jumbotron, .jumbotron-aboutUs, .jumbotron-home-goals, .jumbotron-mv-colour ,.jumbotron-mv, .jumbotron-ourTeam, .jumbotron-med, .jumbotron-mv-main, .jumbotron-mv-main-collab, .jumbotron-home, .jumbotron-home-cyber, .jumbotron-mv-medispeak, .jumbotron-mv-team, .jumbotron-ourTeam, .jumbotron-video, .jumbotron-mission {
        flex-direction: column;
        align-items: center;
        padding: 15px; /* Uniform padding */
    }

    /* Make textual content more suitable for small screens */
    .heading, .content, .youtube-player, .youtube-player-mission, .heading-mission, .youtube-player-mv, .heading-home, .content-home, .content-prod, .content-extra {
        font-size: smaller; /* Reduce font size for space efficiency and readability */
        text-align: center;
        margin: 5px; /* Reduce margin for space efficiency */
    }
}


